import { Link, useParams } from "react-router-dom";
import Links from "components/Links";
import Pagination from "components/Pagination";
import { useRetrieve } from "commons/hooks";
import { PagedCollection } from "interfaces/Collection";
import TResource from "./type";
import { TError } from "commons/utils/types";
import { ROUTES_ORDER } from "../../../commons/constants/paths";

interface ListProps {
  retrieved: PagedCollection<TResource> | null;
  loading: boolean;
  error: TError;
}

const ListView = ({ error, loading, retrieved }: ListProps) => {
  const items = (retrieved && retrieved['hydra:member']) || [];

  return (
    <div>
      <h1>Order List</h1>

      {loading && <div className="alert alert-info">Loading...</div>}
      {error && <div className="alert alert-danger">{error.message}</div>}

      <p>
        <Link to="create" className="btn btn-primary">
          Create
        </Link>
      </p>

      <table className="table table-responsive table-striped table-hover">
        <thead>
          <tr>
            <th>id</th>
            <th>number</th>
            <th>status</th>
            <th>owner</th>
            <th colSpan={2} />
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item["@id"]}>
              <th scope="row">
                <Links
                  items={{
                    href: ROUTES_ORDER.DETAILS(item['id']),
                    name: item['id'],
                  }}
                />
              </th>
              <td>{item['number']}</td>
              <td>{item['status']}</td>
              <td>
                <Link to={ROUTES_ORDER.DETAILS(item['id'])}>
                  <span className="fa fa-search" aria-hidden="true" />
                  <span className="sr-only">Show</span>
                </Link>
              </td>
              <td>
                <Link to={ROUTES_ORDER.EDIT(item['id'])}>
                  <span className="fa fa-pencil" aria-hidden="true" />
                  <span className="sr-only">Edit</span>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Pagination retrieved={retrieved} />
    </div>
  );
};

const List = () => {
  const { page } = useParams<{ page?: string }>();
  const id = (page && decodeURIComponent(page)) || 'api/orders';
console.log('List')
console.log(id)
  const { retrieved, loading, error } =
    useRetrieve<PagedCollection<TResource>>(id);
    // useRetrieve<PagedCollection<TResource>>(`api/orders/${id}`);
  console.log(retrieved)

  return <ListView retrieved={retrieved} loading={loading} error={error} />;
};

export default List;
