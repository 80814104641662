import DashboardLayoutWrapper from "commons/wrappers/DashboardLayoutWrapper";
import Consultations from "../../components/Consultations";
import Orders from "../../components/Orders";
import Patients from "../../components/Patients";
import Products from "../../components/Products";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { styled } from '@mui/system';
import React, { useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "commons/store/hooks";
import { selectUserProfile } from "core/redux/userSlice";
import {
    COLOR_BG_NAV,
    COLOR_PRIMARY_LIGHT,
    PROJECT_NAME
} from "commons/constants/env";
import { useParams } from 'react-router-dom';
import { roles } from 'commons/auth/roles';
import { useSnackbar } from "notistack";
import { useTranslation } from 'react-i18next';

const CustomToggleButton = styled(ToggleButton)(({ theme }) => `
  color: ${theme.palette.secondary.main};
  padding: 6px 16px;
  font-size: 0.875rem;
  border-color: ${theme.palette.primary.light};

  &:hover {
    color: ${theme.palette.primary.dark};
    background: ${COLOR_BG_NAV};
    border-color: ${COLOR_BG_NAV};
  }

  &.Mui-selected {
    color: ${theme.palette.primary.main};
    background: ${theme.palette.primary.light};

    &:hover {
      color: ${theme.palette.primary.dark};
      background: ${COLOR_PRIMARY_LIGHT};
    }
  }
`);

const Dashboard = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const userProfile = useAppSelector(selectUserProfile);
    const {type = 'orders'} = useParams<{
        type: 'consultations' | 'orders' | 'patients' | 'prescriptions'  | 'products'
    }>();
    const { t } = useTranslation();

    let displayConsultations: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.patient.includes(role)
    );
    let displayOrders: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.pharmacist.includes(role)
    );
    let displayPatients: boolean  = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.pharmacist.includes(role)
    );
    let displayProducts: boolean  = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.patient.includes(role)
    );
    let displayPrescriptions: boolean = userProfile.roles.some(
        (role: string) => roles.admin.includes(role) || roles.patient.includes(role)
    );

    /**
     * This function is used to control actual visible dashboard change if route is changed
     */
    const activeSectionController = () => {
        switch (type) {
            default: {
                navigate(`/dashboard/${type || 'orders'}`)
                break;
            }
        }
    }

    /**
     * This function is used to change actual section, also will change route
     * @param value
     */
    const changeDashboardTab = (
        value: 'consultations' | 'orders' | 'patients' | 'prescriptions'  | 'products'
    ) => {
        navigate(`/dashboard/${value}`)
    }

    useEffect(() => {
        document.title = `${PROJECT_NAME} - Dashboard`;
        activeSectionController();

        return () => {
            document.title = PROJECT_NAME;
        };
    }, [dispatch]);

    const renderContent = useCallback(() => {
        switch (type) {
            case 'orders':
                return (<Orders/>);
            case 'products':
                return (<Products/>);
            case 'patients':
                return (<Patients/>);
            case 'consultations':
                return (<Consultations/>);
        }
    }, [type]);

    return (
        <DashboardLayoutWrapper>
            <Box mb={1} display="flex" justifyContent="space-between">
                <ToggleButtonGroup
                    size="small"
                    color="secondary"
                    aria-label="outlined primary button group"
                >
                    {displayOrders && (
                        <CustomToggleButton
                            selected={type === 'orders'}
                            onClick={() => changeDashboardTab('orders')}
                            value={type}
                        >
                            {t('orders')}
                        </CustomToggleButton>
                    )}

                    {displayPatients && (
                        <CustomToggleButton
                            selected={type === 'patients'}
                            onClick={() => changeDashboardTab('patients')}
                            value={type}
                        >
                            {t('patients')}
                        </CustomToggleButton>
                    )}

                    {displayProducts && (
                        <CustomToggleButton
                            selected={type === 'products'}
                            onClick={() => changeDashboardTab('products')}
                            value={type}
                        >
                            {t('products')}
                        </CustomToggleButton>
                    )}

                    {displayConsultations && (
                        <CustomToggleButton
                            selected={type === 'consultations'}
                            onClick={() => changeDashboardTab('consultations')}
                            value={type}
                        >
                            {t('consultations')}
                        </CustomToggleButton>
                    )}

                    {displayPrescriptions && (
                        <CustomToggleButton
                            selected={type === 'prescriptions'}
                            onClick={() => changeDashboardTab('prescriptions')}
                            value={type}
                        >
                            {t('prescriptions')}
                        </CustomToggleButton>
                    )}
                </ToggleButtonGroup>
            </Box>
            {renderContent()}
        </DashboardLayoutWrapper>
    );
};

export default Dashboard;