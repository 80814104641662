import neoVinci from "./images/logo_neo.svg";
import {
    Box,
    Button,
    Container,
    Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTES_CORE } from "commons/constants/paths";
import {
    StyledNavbarList,
    StyledNavbarListItem,
    StyledFooter,
    StyledFooterDescription,
    StyledFooterSection,
    StyledFooterImg,
    StyledFooterImgSpan,
    StyledFooterDescriptionContact,
    StyledMainPage,
} from "./Home.styled";
import { useEffect } from "react";
import { useAppDispatch } from "commons/store/hooks";
import Pharmacy from "../components/Pharmacy";
import { PROJECT_EMAIL, PROJECT_NAME, PROJECT_PHONE } from "../../../../commons/constants/env";
import { useTranslation } from "react-i18next";

function Home() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = `${PROJECT_NAME} - ${t('home-page')}`;
        return () => {
            document.title = PROJECT_NAME;
        };
    }, [dispatch, t]);

    return (
        <StyledMainPage>
            <header>
                <Container maxWidth="lg">
                    <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="center">
                        <Grid container item lg={3} xs={12} spacing={0}>
                            {/* <StyledRefsalLogo src={imprv} alt="Projekt" /> */}
                        </Grid>
                        <Grid container item lg={9} xs={12} spacing={0}>
                            <StyledNavbarList>
                                <StyledNavbarListItem>
                                    <Button size="medium">{t('contact')}</Button>
                                </StyledNavbarListItem>
                                <StyledNavbarListItem>
                                    <Button
                                        variant="outlined"
                                        sx={{borderWidth: "2px", borderColor: "#1D3E73", color: "#1D3E73"}}
                                        to={ROUTES_CORE.REGISTER}
                                        component={Link}
                                    >
                                        {t('sign-up')}
                                    </Button>
                                </StyledNavbarListItem>
                                <StyledNavbarListItem>
                                    <Button
                                        variant="outlined"
                                        sx={{borderWidth: "2px", borderColor: "#1D3E73", color: "#1D3E73"}}
                                        to={ROUTES_CORE.LOGIN}
                                        component={Link}
                                    >
                                        {t('sign-in')}
                                    </Button>
                                </StyledNavbarListItem>
                            </StyledNavbarList>
                        </Grid>
                    </Grid>
                </Container>
            </header>
            <Box style={{placeItems: 'center'}} width={'100%'} mt={24} mb={24} display={'grid'}>
                <Box fontSize={'50px'} textAlign={'center'}>Neo-Vinci Home Template</Box>
            </Box>
            <section>
                <Container maxWidth="lg">
                    <Pharmacy />
                </Container>
            </section>
            <StyledFooter>
                <Container maxWidth="lg">
                    <StyledFooterSection>
                        <StyledFooterDescription>
                            Lorem ipsum
                        </StyledFooterDescription>
                        <StyledFooterDescriptionContact>
                            {t('e-mail')}: {PROJECT_EMAIL} | {t('phone')}: {PROJECT_PHONE} <br/>
                            <br/>
                            {t('working-days')}: {t('monday')}-{t('friday')}, {t('hours')}: 9:00-17:00
                        </StyledFooterDescriptionContact>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                {/*<StyledFooterImg src={headerImg} alt={PROJECT_NAME}/>*/}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                {/*<StyledFooterImg src={'./images/logo-abm.svg'} alt="ABM logo"/>*/}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={1} style={{marginRight: "40px", marginLeft: "40px"}}>
                                {/*<StyledFooterImg src={umw} alt="UMW logo"/>*/}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2} style={{marginRight: "40px"}}>
                                {/*<StyledFooterImg src={} alt="logo"/>*/}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <StyledFooterImgSpan>{t('technical-organizator')}:</StyledFooterImgSpan>
                                <StyledFooterImg src={neoVinci} alt="Neo-Vinci"/>
                            </Grid>
                        </Grid>
                    </StyledFooterSection>
                </Container>
            </StyledFooter>
        </StyledMainPage>
    );
}

export default Home;
