import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "commons/store/store";
import axiosSecureInstance from "commons/axios/axiosSecureInstance";
import { HalResource } from "types/halResource.types";
import { Order } from "../../../types/api.types";
// import { getToken } from "../../../commons/auth/tokens";
// import { EventSourcePolyfill } from "event-source-polyfill";

export interface OrderState {
    orders: {
        itemsPerPage: number;
        totalItems: number;
        data: any[];
    };
    details: any | null;
}

const initialState: OrderState = {
    orders: {
        itemsPerPage: 0,
        totalItems: 0,
        data: [],
    },
    details: null,
};

type FetchOrderParams = {
    page?: number | undefined;
};

export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async (params: FetchOrderParams, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<
                HalResource<Order[]>
            >('/api/orders', {
                headers: {
                    accept: 'application/hal+json',
                },
                params: params,
            });

            // if (response?.headers?.link) {
            //     // Extract the hub URL from the Link header
            //     const hubUrl = response.headers?.link.match(/<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/)[1];
            //     // const hubUrl = 'http://localhost/.well-known/mercure';
            //
            //     // Append the topic(s) to subscribe as query parameter
            //     const hub  = new URL(hubUrl, window.origin);
            //     // const hub = new URL(hubUrl);
            //     hub.searchParams.append('topic', `${response?.config.baseURL}${response?.config.url}`);
            //     hub.searchParams.append('topic', `${response?.config.baseURL}${response?.config.url}/{id}`);
            //     // hub.searchParams.append('topic', encodeURIComponent(response?.config.baseURL || '' + response?.config.url || ''));
            //     // hub.searchParams.append('topic', 'https://127.0.0.1:8000/api/orders');
            //
            //     // const token = getToken();
            //     // const options = {
            //     //     headers: {
            //     //         // Authorization: `Bearer eyJhbGciOiJIUzI1NiJ9.eyJtZXJjdXJlIjp7InB1Ymxpc2giOlsiKiJdLCJzdWJzY3JpYmUiOlsiaHR0cHM6Ly8xMjcuMC4wLjE6OTAwMS9hcGkvb3JkZXJzIiwie3NjaGVtZX06Ly97K2hvc3R9L2FwaS9vcmRlcnMve2lkfS5qc29ubGQiLCIvLndlbGwta25vd24vbWVyY3VyZS9zdWJzY3JpcHRpb25zey90b3BpY317L3N1YnNjcmliZXJ9Il0sInBheWxvYWQiOnsidXNlciI6Imh0dHBzOi8vZXhhbXBsZS5jb20vdXNlcnMvZHVuZ2xhcyIsInJlbW90ZUFkZHIiOiIxMjcuMC4wLjEifX19.tZFq5PKmFpwOdJIDa1lNqPCZVR0KOQyuiKzYsQBVH_s`,
            //     //         Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MjQ2NjA2ODYsImV4cCI6MTc1NjE5NjY4Niwicm9sZXMiOlsiUk9MRV9BRE1JTiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6ImFkbWluQG5lby12aW5jaS5wbCIsInJvbGUiOiJST0xFX0FETUlOIiwibWVyY3VyZSI6eyJwdWJsaXNoIjpbIioiXSwic3Vic2NyaWJlIjpbIioiXX19.MBDOChe-4C_kcQgakbB6SpNmLcwL4m_7RaEIOERj0jCxPSm1L9capTbRnqG7B0djSS-IkBGIbfZ0gsVqLQo-M-2mPTjrwoYnnE5Ox0BddlwZneIF5GR4M0z80ieeZu1gx0oDe0Q3k0mrPI13_BogMlTkYlvK230aDxVpKPEF1CggE7ql7QWaK6k_49OANyNbmeMQC-KtGA3YwQ7E9UPd-geidwcJdF2CU5LcOS0WT8lU9hmqXl2k-QQFViJ86Tckqm4JSDNQ52TrG2LrTm8PLWDclz60ofs8Cdl0tf0cZZmFatAuoG3AciLV-nwKneq5l9Dy_T40DcGnSbEzWTMluA`,
            //     //         mercureAuthorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MjQ2NjA2ODYsImV4cCI6MTc1NjE5NjY4Niwicm9sZXMiOlsiUk9MRV9BRE1JTiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6ImFkbWluQG5lby12aW5jaS5wbCIsInJvbGUiOiJST0xFX0FETUlOIiwibWVyY3VyZSI6eyJwdWJsaXNoIjpbIioiXSwic3Vic2NyaWJlIjpbIioiXX19.MBDOChe-4C_kcQgakbB6SpNmLcwL4m_7RaEIOERj0jCxPSm1L9capTbRnqG7B0djSS-IkBGIbfZ0gsVqLQo-M-2mPTjrwoYnnE5Ox0BddlwZneIF5GR4M0z80ieeZu1gx0oDe0Q3k0mrPI13_BogMlTkYlvK230aDxVpKPEF1CggE7ql7QWaK6k_49OANyNbmeMQC-KtGA3YwQ7E9UPd-geidwcJdF2CU5LcOS0WT8lU9hmqXl2k-QQFViJ86Tckqm4JSDNQ52TrG2LrTm8PLWDclz60ofs8Cdl0tf0cZZmFatAuoG3AciLV-nwKneq5l9Dy_T40DcGnSbEzWTMluA`
            //     //     },
            //     // };
            //
            //     // Subscribe to updates
            //     // const eventSource = new EventSource(hub);
            //     const eventSource = new EventSource(hub, {withCredentials: true});
            //     eventSource.onmessage = event => console.log(event.data);
            //
            //     // const es = new EventSourcePolyfill(u, {
            //     //     headers: {
            //     //         Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE3MjQ2NjA2ODYsImV4cCI6MTc1NjE5NjY4Niwicm9sZXMiOlsiUk9MRV9BRE1JTiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6ImFkbWluQG5lby12aW5jaS5wbCIsInJvbGUiOiJST0xFX0FETUlOIiwibWVyY3VyZSI6eyJwdWJsaXNoIjpbIioiXSwic3Vic2NyaWJlIjpbIioiXX19.MBDOChe-4C_kcQgakbB6SpNmLcwL4m_7RaEIOERj0jCxPSm1L9capTbRnqG7B0djSS-IkBGIbfZ0gsVqLQo-M-2mPTjrwoYnnE5Ox0BddlwZneIF5GR4M0z80ieeZu1gx0oDe0Q3k0mrPI13_BogMlTkYlvK230aDxVpKPEF1CggE7ql7QWaK6k_49OANyNbmeMQC-KtGA3YwQ7E9UPd-geidwcJdF2CU5LcOS0WT8lU9hmqXl2k-QQFViJ86Tckqm4JSDNQ52TrG2LrTm8PLWDclz60ofs8Cdl0tf0cZZmFatAuoG3AciLV-nwKneq5l9Dy_T40DcGnSbEzWTMluA`
            //     //     }
            //     // })
            //     //
            //     // es.onmessage = (event: any) => console.log(event.data);
            //     // es.onerror = (e: any) => console.log(e);
            // }

            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const fetchOrder = createAsyncThunk(
    'orders/fetchOrder', async (id: string, thunkAPI) => {
        try {
            const response = await axiosSecureInstance.get<Order>(`/api/orders/${id}`);
            return response.data;
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
});

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        resetOrders(state) {
            state.orders = initialState.orders;
        },
        resetOrder(state) {
            state.details = initialState.details;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.orders.data = action.payload._embedded?.item || [];
            state.orders.itemsPerPage = action.payload.itemsPerPage;
            state.orders.totalItems = action.payload.totalItems;
        });
        builder.addCase(fetchOrder.fulfilled, (state, action) => {
            state.details = action.payload;
        });
    },
});

export const selectOrders = (state: RootState) => state.orders.orders.orders;
export const selectOrder = (state: RootState) => state.orders.orders.details;

export const {
    resetOrders,
    resetOrder,
} = ordersSlice.actions;

export default ordersSlice.reducer;
