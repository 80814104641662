import { useField } from "formik";
import React, { ReactNode } from "react";
import xor from "lodash/xor";
import { CheckboxProps, FormControlLabel } from "@mui/material";
import { FormControlLabelProps } from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export interface CheckboxFormikProps
  extends Omit<CheckboxProps, 'name' | 'onChange' | 'onBlur' | 'error'> {
  name: string;
  label?: ReactNode;
  labelPlacement?: FormControlLabelProps["labelPlacement"];
  status?: boolean;
}

const CheckboxFormik = ({
  name,
  label,
  value,
  labelPlacement,
  status,
  ...props
}: CheckboxFormikProps) => {
  const [field, meta, helpers] = useField(name);
  // const { value } = meta;
  const { setValue } = helpers;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (Array.isArray(field.value)) {
          let values = xor(field.value, [value]).slice()
          values = values.filter(el => el)
          if (values.length === 0) {
              setValue([])
          } else {
              setValue(xor(field.value, [value]))
          }
      } else {
          setValue(event.target.checked ?? false);
      }
  }

  return (
    <FormControlLabel
      onBlur={field.onBlur}
      control={
        <Checkbox
          disabled={status}
          checked={Array.isArray(field.value) ? field.value.includes(value) : field.value}
          // onChange={Array.isArray(field.value) ? () => onChange() : field.onChange}
          onChange={onChange}
          name={name}
          required={props?.required}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      disabled={status}
    />
  );
};

export default CheckboxFormik;
